import React from 'react';
import { Link, useParams } from "react-router-dom";
import { Button, Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGamepad } from '@fortawesome/free-solid-svg-icons';

import { GameInfo } from '../components/gameinfo/GameInfo';
import { Separator } from '../components/common/Separator';

// Use these images
import ac_capsule from '../assets/images/games/Arcade Classics/Small Capsule.png';
import nt2_capsule from '../assets/images/games/NinjaThea 2/Small Capsule.png';

export const Games = () => {

    const { gameId } = useParams();

    function RenderGameInfo() {
        if (gameId) {
            return <GameInfo gameId={gameId} />;
        } else {
            return <DefaultGameInfo />;
        }
    }

    function DefaultGameInfo() {
        return (
            <Container className='mt-2 wrapper'>
                <Container className='mt-4 mb-3 center'>
                    <h1><FontAwesomeIcon icon={faGamepad} />&nbsp;Games</h1>
                </Container>
                < Separator />
                <Row className='align-items-center mb-4'>
                    <Col md={4} className='text-center'>
                        <img src={nt2_capsule} alt='NinjaThea 2' style={{ maxWidth: '100%' }} />
                    </Col>
                    <Col md={8} className='mt-4'>
                        <h3>NinjaThea 2</h3>
                        <p>
                            The sequel to <a className='cstm-link' href='https://store.steampowered.com/app/2005800/NinjaThea/' target='_blank' rel='noopener noreferrer'>NinjaThea</a>, offering engaging gameplay and new challenges.
                        </p>
                        <Button variant='dark' href='https://store.steampowered.com/app/2567920/NinjaThea_2/' target='_blank'>
                            Visit on Steam
                        </Button>
                    </Col>
                </Row>
                <Row className='align-items-center mb-4'>
                    <Col md={4} className='text-center'>
                        <img src={ac_capsule} alt='Arcade Classics' style={{ maxWidth: '100%' }} />
                    </Col>
                    <Col md={8} className='mt-4'>
                        <h3>Arcade Classics</h3>
                        <p>
                            A collection of remade arcade classics for nostalgic fun.
                        </p>
                        <Button variant='dark' href='https://store.steampowered.com/app/2170700/Arcade_Classics/' target='_blank'>
                            Visit on Steam
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col className='mt-3 mb-2'>
                        <Container className='mb-4'>
                            <p>
                                If you have creative ideas for games, we’d like to hear them. Drop us a message!
                            </p>
                            <Button as={Link} to='/contact' variant='dark'>
                                Contact
                            </Button>
                        </Container>
                    </Col>
                </Row>
            </Container>
        );
    }

    return (
        <RenderGameInfo />
    );
};