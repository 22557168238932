import React from 'react';
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGamepad } from '@fortawesome/free-solid-svg-icons'
import { faSteam } from '@fortawesome/free-brands-svg-icons'
import { Separator } from '../../components/common/Separator';


export const ArcadeClassics = () => {

    return (
        <Container className='mt-2 wrapper'>
            <Container className='mt-4 mb-3 center'>
                <h1><FontAwesomeIcon icon={faGamepad} />&nbsp;Arcade Classics</h1>
            </Container>
            <Separator />
            <Row>
                <Col className='mt-3 mb-2' md>
                    <Container className='mb-4'>
                        <p>
                            Challenging and fun to play remake of a select few arcade classics, in a 16bit friendly art style
                            and a fresh soundtrack.
                        </p>
                        <p>
                            <b>Astro Impact</b> and <b>Brick Smash</b> available on relase, with <b>Alien Assault</b> coming 2024.
                        </p>
                        <p>
                            No other titles planned at the moment, but if you have any favorites you'd like to see remade,
                            don't hesitate to <Link className='cstm-link' to={'/contact'}>get in touch</Link>.
                        </p>
                        <p>
                            Get the game on&nbsp;
                            <a className='cstm-link' href='https://store.steampowered.com/app/2170700/Arcade_Classics/'>
                                Steam <FontAwesomeIcon icon={faSteam} title='Steam' />
                            </a>
                        </p>
                    </Container>
                </Col>
            </Row>
        </Container>
    )
}